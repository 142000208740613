import styled from "styled-components"
import Typography from "@material-ui/core/Typography"

const StaticTypography = styled(Typography)`
  font-family: Tahoma, Verdana, Segoe, sans-serif;

  line-height: 1.4;
`

export default StaticTypography
