import React from "react"
import Layout from "../../Layout"
import Meta from "../../components/Meta"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import Spacer from "../../components/Spacer"
import StaticHeadline from "../../components/static/StaticHeadline"
import StaticParagraph from "../../components/static/StaticParagraph"
import StaticContainer from "../../components/static/StaticContainer"

export default function Impressum({ data }) {
  return (
    <Layout>
      <Meta title="Impressum" />
      <Header />
      <main>
        <StaticContainer maxWidth="lg">
          <Spacer />
          <StaticHeadline variant="h1" align="center" gutterBottom>
            Impressum
          </StaticHeadline>
          <StaticHeadline variant="h2" gutterBottom>
            Anschrift
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            OMlocal! GmbH
            <br /> Geschäftsführer: Thorsten Loth &amp; Christoph Ehrke
            <br />
            Falkstraße 73-77
            <br /> 47058 Duisburg
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Telefon: +49 203 600 161 60
            <br /> E-Mail: info@omlocal.de
            <br /> Web:{" "}
            <a target="_blank" rel="norefferer" href="https://omlocal.de/">
              https://omlocal.de
            </a>
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Verantwortlicher i.S.d. § 55 Abs. 2 RStV:
            <br />
            Thorsten Loth, Falkstr. 73-77, 47058 Duisburg
          </StaticParagraph>
          <StaticHeadline variant="h2">Realisationspartner</StaticHeadline>
          <StaticHeadline variant="h3" gutterBottom>
            User Experience &amp; Interface Design
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Big Sun GmbH
            <br />
            Remscheider Straße 11a
            <br />
            40215 Düsseldorf
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            <a target="_blank" rel="norefferer" href="https://www.bigsun.de">
              https://www.bigsun.de
            </a>
          </StaticParagraph>
          <StaticHeadline variant="h3" gutterBottom>
            Progressive Web App Development
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Klaus Breyer
            <br />
            Erich-Nehlhans-Str. 27
            <br />
            10247 Berlin
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            <a target="_blank" rel="norefferer" href="https://v01.io">
              https://v01.io{" "}
            </a>
          </StaticParagraph>
        </StaticContainer>
      </main>
      <Footer />
    </Layout>
  )
}
